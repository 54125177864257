import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { Link } from 'gatsby';
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"


const WhiteBox = styled.div`
	display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 1rem;
    align-items: top:
`

const ImageBox = styled.div`
	display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 1rem;
    align-items: center:
    justify-content: center;
`

const CardBody = styled.div`
    border-radius: 0;
    box-shadow: 0 20px 40px -14px rgb(0 0 0 / 25%);
    display: flex;
    flex-direction: column;
    overflow: hidden;
	height: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom:20px;
`

function MakeComicLinkButton({className, bShowButton, text, slug, bookId}) 
{
    if(bShowButton !== null && bShowButton) 
    {
        return (
            <div>
                <Link 
                    key={bookId}
                    className={className} 
                    to={"/comics/" + slug}>
                        {text}
                </Link>
            </div>
        );
    }

    return null;
}

function MakePurchaseButton({className, bShowButton, text, purchaseLink, btnId})
{
    if(bShowButton !== null && bShowButton) 
    {
        return (
            <div>
                <a 
                    key={btnId}
                    className={className} 
                    href={purchaseLink}
                >
                    {text}
                </a>
            </div>
        );
    }

    return null;
}

function H3Title({text}) 
{
    return <h3>{text}</h3>
}

function FeaturedText({bShowFeaturedText, htmlstyle, html})
{
    console.log("html is " + html);
    if(bShowFeaturedText !== null && bShowFeaturedText) 
    {
        return <div className={htmlstyle} dangerouslySetInnerHTML={{ __html: html }} />;
    }

    return null;
}

function GetPageByTag(pages, tag) 
{
    if(pages.length <= 0) 
    {
        console.log("ERROR: GetPageByTag has 0 pages passed in!");
        return null;
    }

    for(var i=0; i<pages.length; ++i) 
    {
        var pageFrontMatter = pages[i].frontmatter;
        if(pageFrontMatter.hasOwnProperty('tags') && pageFrontMatter.tags !== null && pageFrontMatter.tags.includes(tag))
        {
            return pages[i];
        }
    }

    //return the first page by default.
    return pages[0];
}

const FullCover = ({bookId, html, frontmatter, bShowFeaturedText, bShowButton, pages, className}) => {
    const { title, featuredText} = frontmatter;
    const { featuredImage } = frontmatter;

    //we have a featured image.
    if(pages.length > 0 && title != null)
    {
        // const page = pages[0];
        const page = GetPageByTag(pages, "preview");
        // const image = convertToBgImage(getImage(featuredImage));
        const image = getImage(featuredImage)
        // console.log("frontmatter: " + JSON.stringify(frontmatter));
        return (
            <CardBody>
                <Container fluid>
                    <Row lg="auto">
                        <Col md="6" key={'col_blah'}>
                            <ImageBox>
                                <Link to={"/comics/" + page.parent.relativeDirectory} key={page.id}>
                                    <GatsbyImage
                                        image={image}
                                        alt="cover image"
                                    />
                                </Link>
                                <MakeComicLinkButton 
                                    className="btn text-btn-gold btn-default btn-lg btn-sizing"
                                    key={'comiclink_' + title + '_' + bookId} 
                                    bShowButton={bShowButton} 
                                    text="Preview" 
                                    slug={frontmatter.slug + '/' + page.frontmatter.slug} 
                                    bookId={bookId} 
                                />
                                <MakePurchaseButton
                                    className="btn text-btn-gold btn-default btn-lg btn-sizing"
                                    key={'purchaselink_' + title + '_' + bookId} 
                                    bShowButton={bShowButton} 
                                    text="Get the Book on Amazon" 
                                    purchaseLink={frontmatter.purchaseLink}
                                    slug={frontmatter.slug} 
                                    bookId={bookId} 
                                />
                            </ImageBox>
                        </Col>
                        <Col md="6">
                            <WhiteBox className="d-flex align-items-start flex-column">
                                <H3Title 
                                    key={'title_' + title + '_' + bookId} 
                                    text={title}
                                />
                                <FeaturedText 
                                    key={'featuredtext_' + title} 
                                    bShowFeaturedText={bShowFeaturedText} 
                                    html={html} 
                                />
                            </WhiteBox>                        
                        </Col>
                    </Row>
                </Container>
            </CardBody>
        )
    }
    else 
    {
        return (
            <div>
                <GatsbyImage alt="featured comic image" image={getImage(frontmatter.featuredImage)} />
            </div>
        )
    }
}

const StyledFullCover = styled(FullCover)`
  box-shadow: 0 20px 40px -14px rgb(0 0 0 / 25%);
  border: 4px;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default StyledFullCover
